import "./faq.scss"

import React, { useState } from "react"

import { StaticImage } from "gatsby-plugin-image"

import { mdiChevronDown, mdiChevronUp } from "@mdi/js"
import Icon from "@mdi/react"

import CustomIcon from "../components/CustomIcon"
import Dash from "../components/Dash"
import Layout from "../components/Layout"
import faqHeaderBg from "../images/faq/faqHeaderBg.webp"
import { getAllLocales, getCurrentLanguagePage } from "../plugins/common"
import { CustomMarkdown } from "../plugins/sanitizehtml"

const simplePageShape_left = "../images/simplePage/simplePageShape_left.webp"
const simplePageShape_right = "../images/simplePage/simplePageShape_right.webp"
const faqLeftShapes = "../images/faq/faqLeftShapes.webp"
const faqRightEmpty = "../images/faq/faqRightEmpty.webp"

const FaqPage = ({ pageContext, location }) => {
  const { data, general, form } = pageContext
  const { title, faq, translation, meta, localizations, locale } = data
  // console.log(pageContext.data)
  const aleaToken = general.aleaToken
  const languageOptions = general.allStrapiLanguages?.nodes ?? "en"
  const lang = getCurrentLanguagePage(location, languageOptions)
  const enableFooterForm = data.footerForm?.enableFooter
  const footerForm = {
    ...data.footerForm,
    ...form.freeQuote,
  }

  const [section, setSection] = useState(0)
  const [questions, setQuestions] = useState([])

  const updateSection = index => {
    if (section !== index) setQuestions([])
    setSection(index)
  }
  const isSectionOpened = index => index === section
  const isQuestionOpened = index =>
    questions.findIndex(qaIndex => qaIndex === index) >= 0 ? true : false

  const updateQuestions = index => {
    const qIndex = questions.findIndex(val => val === index)
    const questionsCopy = JSON.parse(JSON.stringify(questions))
    qIndex >= 0 ? questionsCopy.splice(qIndex, 1) : questionsCopy.push(index)
    setQuestions(questionsCopy)
  }

  const getChevon = opened => (opened ? mdiChevronUp : mdiChevronDown)

  return (
    <Layout
      props={general}
      meta={meta}
      localizations={getAllLocales(localizations, locale)}
      location={location}
      lang={lang}
      hideNewsletter={true}
      token={aleaToken}
      footerForm={enableFooterForm ? footerForm : null}
    >
      <div className="faq">
        <div
          className="container-fluid text-center header position-relative"
          style={{ backgroundImage: `url(${faqHeaderBg})` }}
        >
          <StaticImage
            sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
            className="left position-absolute"
            src={simplePageShape_left}
            alt=""
            layout="constrained"
          />
          <StaticImage
            sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
            className="right position-absolute"
            src={simplePageShape_right}
            alt=""
            layout="constrained"
          />
          <h1 className="ming">{title}</h1>
        </div>
        <div className="position-relative faq-body">
          <StaticImage
            sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
            className="left position-absolute"
            src={faqLeftShapes}
            alt=""
            layout="constrained"
          />
          <StaticImage
            sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
            className="right position-absolute"
            src={faqRightEmpty}
            alt=""
            layout="constrained"
          />
          <div className="container content card soft-corners soft-shadows">
            <div className="row d-flex flex-lg-row flex-column">
              <div className="col-lg-8 col-12 order-lg-0 order-1 left-col">
                {faq.map((section, sectionIndex) => (
                  <div
                    role="button"
                    tabIndex={0}
                    className={`faq-section  contanier`}
                    key={`sectionIndex-${sectionIndex}`}
                    onClick={() => updateSection(sectionIndex)}
                    onKeyDown={() => updateSection(sectionIndex)}
                  >
                    <span id={`section_${section.sectionTitle}`}></span>
                    <div className="faq-section-header row">
                      <div className="col-11 section-header-title">
                        <h2 className="subtitle ming ">
                          {section.sectionTitle}
                        </h2>
                        <Dash />
                      </div>
                      <div className="col-1 section-chevon">
                        <Icon
                          path={getChevon(isSectionOpened(sectionIndex))}
                          size={1.5}
                        />
                      </div>
                    </div>
                    <div
                      className={`faq-section-content  ${
                        isSectionOpened(sectionIndex) ? "d-block" : "d-none"
                      }`}
                    >
                      {section.questionAnswer.map((qa, qaIndex) => (
                        <div
                          role="button"
                          tabIndex={-1}
                          className="faq-qa contanier"
                          key={`questionIndex-${qaIndex}`}
                          onClick={() => updateQuestions(qaIndex)}
                          onKeyDown={() => updateQuestions(qaIndex)}
                        >
                          <div className="faq-qa-question row">
                            <h3 className="col-11 mb-0 title">{qa.title}</h3>
                            <div className="col-1 question-chevon">
                              <Icon
                                path={getChevon(isQuestionOpened(qaIndex))}
                                size={1.5}
                              />
                            </div>
                          </div>
                          <div
                            className={`faq-qa-answer ${
                              isQuestionOpened(qaIndex) ? "d-block" : "d-none"
                            }`}
                          >
                            <CustomMarkdown html={qa.content}></CustomMarkdown>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
              <div className="col-lg-4 col-12 order-lg-1 order-0 sideBar">
                <div className="section-menu">
                  <div className="label ming">
                    {translation.faqContentLabel}
                  </div>
                  {faq.map((item, index) => {
                    return (
                      <div
                        role="button"
                        tabIndex={-2}
                        key={index}
                        className="faq-index d-flex flex-column p-2"
                        onClick={() => updateSection(index)}
                        onKeyDown={() => updateSection(index)}
                      >
                        <div className="d-flex flex-row row">
                          <div className="col-1 customIcon ">
                            <CustomIcon className="icon" icon="rightArrow" />
                          </div>
                          <div className="col-11">
                            <a href={`#section_${item.sectionTitle}`}>
                              {item.sectionTitle}
                            </a>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default FaqPage
